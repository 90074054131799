import React from 'react'
import MicrobladingService from '../components/ServiceDetail/Microblading/MicrobladingService'

const MicrobladingPage = () => {
  return (
    <MicrobladingService />
  )
}

export default MicrobladingPage;
