import React from 'react'
import AboutUs from '../components/AboutUs/AboutUs'
import Memberships from '../components/MembershipManagement/Memberships'

const AboutPage = () => {
  return (
    <Memberships />
  )
}

export default AboutPage
