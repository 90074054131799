import React from 'react'
import ServiceDetail from '../components/ServiceDetail/ServiceDetail'
import MakeUp from '../components/ServiceDetail/MakeUp/MakeUp'

const MakeupPage = () => {
  return (
    <div>
        {/* <ServiceDetail /> */}
        <MakeUp />
    </div>
  )
}

export default MakeupPage
